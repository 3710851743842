<template>
  <div>
    <div class="index researchProject1">
      <div style="margin-bottom: 10px">
        <el-button type="primary" @click="dialogFormVisible = true"
          >+添加上级机构</el-button
        >
      </div>
      <el-table
        class="ly_table"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center'
        }"
        :data="form2"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
        <el-table-column
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column prop="governmentName" label="机构名称">
          <template slot-scope="scope">
            <div style="cursor: pointer;" @click="pushNewData(scope.row)">
              {{ scope.row.governmentName }}
            </div>
          </template></el-table-column
        >
        <el-table-column
          prop="governmentPhone"
          label="机构联系电话"
        ></el-table-column>
        <el-table-column
          prop="administrativeLevel"
          label="行政级别"
        ></el-table-column>
        <el-table-column prop="principal" label="负责人"></el-table-column>
        <el-table-column label="任免信息">
          <template slot-scope="scope">
            <div v-if="scope.row.beginTime">
              {{ scope.row.beginTime }}
              {{ scope.row.endTime ? "-" + scope.row.endTime : "-至今" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="responsibilities"
          label="职责范围"
        >
        <template slot-scope="scope">
            <div>
              <el-popover
              placement="right"
              width="300"
              trigger="hover"
              :content="scope.row.responsibilities"
            >
              <div slot="reference">
                <div v-if="(scope.row.responsibilities !== null) && (scope.row.responsibilities !== '')">
                  {{scope.row.responsibilities.substring(0,20)}}
                  <span v-if="scope.row.responsibilities.length>20">...</span>
                </div>
              </div>
            </el-popover>
            </div>
          </template></el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <!-- <button-table
              @buttonClick="openEdit(scope.row, scope.$index)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            /> -->
            <button-table
              @buttonClick="deleteEx(scope.row, scope.$index)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <div
        style="color: #7c7f8e; font-size: 13px; padding: 20px"
        v-if="personType"
      >
        <p>创建人：{{ editTalentInfo.createName }}</p>
        <p>修改人：{{ editTalentInfo.updateName }}</p>
        <p>更新时间：{{ editTalentInfo.updateTime }}</p>
        <p>收录时间：{{ editTalentInfo.createTime }}</p>
      </div>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[3, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <el-dialog
        title="添加上级机构"
        :visible.sync="dialogFormVisible"
        center
        @close="handleClose"
      >
        <el-form :model="form" :rules="formRules" ref="ruleForm">
          <el-form-item
            label="机构名称:"
            label-width="120px"
            prop="governmentName"
          >
            <el-select
              v-model="form.governmentName"
              filterable
              remote
              reserve-keyword
              placeholder="请输入关键词"
              :remote-method="remoteMethod"
              :loading="loading"
              style="width: 430px"
              @change="changeCompany(form.governmentName)"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.governmentName"
                :value="item.governmentName"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="筛选标签:"
            label-width="120px"
            prop="selectLabel"
          >
            <el-radio-group v-model="form.selectLabel">
              <el-radio label="直属机构">直属机构</el-radio>
              <el-radio label="内设机构">内设机构</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="机构联系电话:"
            label-width="120px"
            prop="governmentPhone"
          >
            <el-input
              v-model="form.governmentPhone"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="行政级别:"
            label-width="120px"
            prop="administrativeLevel"
          >
            <!-- <dic-radio-group
              :code.sync="form.administrativeLevel"
              type-code="031"
            /> -->
            <el-select v-model="form.administrativeLevel" clearable placeholder="请选择">
            <el-option label="国级" value="国级" />
            <el-option label="省（部）级" value="省（部）级" />
            <el-option label="厅（局）级" value="厅（局）级" />
            <el-option label="处级" value="处级" />
            <el-option label="科级" value="科级" />
          </el-select>
          </el-form-item>
          <el-form-item
            label="机构负责人:"
            label-width="120px"
            prop="principal"
          >
            <el-input v-model="form.principal" style="width: 430px"></el-input>
          </el-form-item>
          <el-form-item label="任免信息:" label-width="120px">
            <el-date-picker
              v-model="form.beginTime"
              type="date"
              placeholder="开始时间"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <el-date-picker
              v-model="form.endTime"
              type="date"
              placeholder="至今"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="职责范围:"
            label-width="120px"
            prop="responsibilities"
          >
            <el-input
              v-model="form.responsibilities"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 20 }"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addPersonEx('ruleForm')"
            >确 定</el-button
          >
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="编辑上级机构"
        :visible.sync="dialogFormVisible1"
        center
        @close="handleClose"
      >
        <el-form :model="editform" :rules="formRules" ref="editruleForm">
          <el-form-item
            label="机构名称:"
            label-width="120px"
            prop="governmentName"
          >
            <el-input
              v-model="editform.governmentName"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="筛选标签:"
            label-width="120px"
            prop="selectLabel"
          >
            <el-radio-group v-model="editform.selectLabel">
              <el-radio label="直属机构">直属机构</el-radio>
              <el-radio label="内设机构">内设机构</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="机构联系电话:"
            label-width="120px"
            prop="governmentPhone"
          >
            <el-input
              v-model="editform.governmentPhone"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="行政级别:"
            label-width="120px"
            prop="administrativeLevel"
          >
            <!-- <dic-radio-group
              :code.sync="editform.administrativeLevel"
              type-code="031"
            /> -->
            <el-select v-model="editform.administrativeLevel" clearable placeholder="请选择">
            <el-option label="国级" value="国级" />
            <el-option label="省（部）级" value="省（部）级" />
            <el-option label="厅（局）级" value="厅（局）级" />
            <el-option label="处级" value="处级" />
            <el-option label="科级" value="科级" />
          </el-select>
          </el-form-item>
          <el-form-item label="机构负责人:" label-width="120px" prop="name">
            <el-input
              v-model="editform.principal"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item label="任免信息:" label-width="120px">
            <el-date-picker
              v-model="editform.beginTime"
              type="date"
              placeholder="开始时间"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <el-date-picker
              v-model="editform.endTime"
              type="date"
              placeholder="至今"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="职责范围:"
            label-width="120px"
            prop="responsibilities"
          >
            <el-input
              v-model="editform.responsibilities"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 20 }"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="editEx('editruleForm')"
            >确 定</el-button
          >
          <el-button @click="cancelEx('editruleForm')">取 消</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- <div class="bottom" v-if="personType">
      <el-button @click="cancelItem" type="primary">确定</el-button>
      <el-button type="info" @click="cancelItem">取消</el-button>
    </div> -->
  </div>
</template>

<script>
import ButtonTable from "@/components/ButtonTable.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import {
  getHigherInstitutionsById,
  governmentHighInstitutionSave,
  deleteGovernmentPrincipleById,
  getGovernmentByName,
  governmentHighInstitutionUpdate,
  getByGovernmentNameList,
  updateGovernment
} from "@/api/commercial.js";
import { pagination } from "@/mixins/pagination";
import CascaderArea from "@/components/CascaderArea.vue";
import DicRadioGroup from "@/components/DicRadioGroup.vue";
export default {
  inject: ["reload"],
  mixins: [pagination],
  components: {
    ButtonTable,
    CascaderArea,
    DicCheckboxGroup,
    DicRadioGroup
  },
  data() {
    return {
      index: 0,
      form: {
        principal: "", //负责人
        position: "", //职务职称
        administrativeLevel: "", //行政级别
        beginTime: "", //任免信息开始时间
        endTime: "", //任免信息结束时间
        // contactInformation: "", //联系方式
        responsibilities: "", //职责范围
        selectLabel: ""
      },
      PayTime: [],
      formRules: {
        governmentName: [
          { required: true, message: "请输入负责人", trigger: "blur" }
        ],
        selectLabel: [
          { required: true, message: "请输入筛选标签", trigger: "blur" }
        ],
        administrativeLevel: [
          { required: true, message: "请填写行政级别", trigger: "blur" }
        ]
      },
      queryInfo: {
        id: "", //人才编号
        pageSize: 10,
        pageNum: 1
      },
      loading: false,
      form2: [],
      dialogFormVisible: false,
      dialogFormVisible1: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            }
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            }
          }
        ]
      },
      editform: {
        name: "", //负责人
        position: "", //职务职称
        administrativeLevel: "", //行政级别
        beginTime: "", //任免信息开始时间
        endTime: "", //任免信息结束时间
        contactInformation: "", //联系方式
        responsibilities: "" //职责范围
      },
      options: []
    };
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
    firstId() {
      return this.$route.query.firstId;
    }
  },
  props: {
    editTalentInfo: {
      type: Object
    },
    personData: {
      type: String
    },
    personType: {
      type: String
    }
  },
  created() {
    if (this.personType) {
      this.search();
    }
  },
  methods: {
    // 日期时间
    GetzhifuTime() {
      if (this.PayTime != null) {
        this.form.beginTime = this.PayTime[0];
        this.form.endTime = this.PayTime[1];
        this.editform.beginTime = this.PayTime[0];
        this.editform.endTime = this.PayTime[1];
      }
    },
    searchCountry(val) {
      this.form.sponsorCountry = val;
    },
    searchProvince(val) {
      this.form.sponsorProvince = val;
    },
    searchCity(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.form.sponsorCity = val;
      }
    },
    searchCountry1(val) {
      this.editform.sponsorCountry = val;
    },
    searchProvince1(val) {
      this.editform.sponsorProvince = val;
    },
    searchCity1(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.editform.sponsorCity = val;
      }
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(async () => {
          this.loading = false;
          const queryname = query;
          const res = await getByGovernmentNameList({ name: queryname });
          this.options = res.data;
        }, 200);
      } else {
        this.options = [];
      }
    },
    changeCompany(data) {
      let dataList = this.options;
      let filtered = dataList.find(n => {
        return n.governmentName === data;
      });
      this.form = filtered;
      this.options = [];
    },
    // 清空表单
    deleteForm() {
      this.form = {
        name: "", //负责人
        position: "", //职务职称
        administrativeLevel: "", //行政级别
        beginTime: "", //任免信息开始时间
        endTime: "", //任免信息结束时间
        contactInformation: "", //联系方式
        responsibilities: "" //职责范围
      };
      this.editform = {
        name: "", //负责人
        position: "", //职务职称
        administrativeLevel: "", //行政级别
        beginTime: "", //任免信息开始时间
        endTime: "", //任免信息结束时间
        contactInformation: "", //联系方式
        responsibilities: "" //职责范围
      };
      this.dialogFormVisible = false;
      this.dialogFormVisible1 = false;
    },
    // 查询
    async search() {
      let personDatas;
      if (!this.personData) {
        personDatas = Number(this.firstId);
      } else {
        personDatas = Number(this.personData);
      }
      if (this.personType) {
        this.queryInfo.id = personDatas;
      }
      const res = await getHigherInstitutionsById({ id: personDatas });
      if (res.code === 200) {
        this.form2 = res.data;
      }
    },
    // 添加工作经历
    addPersonEx(formName) {
      if (this.personType) {
        this.$refs[formName].validate(async valid => {
          if (valid) {
            let personDatas;
            if (!this.personData) {
              personDatas = Number(this.firstId);
            } else {
              personDatas = Number(this.personData);
            }
            Object.assign(this.form, {
              Authorization: this.Authorization,
              governmentId: personDatas
            });
            const res = await governmentHighInstitutionUpdate(this.form);
            if (res.code === 200) {
              this.dialogFormVisible = false;
              this.$message.success("新增成功");
              this.deleteForm();
              this.search();
            }
          }
        });
      } else {
        this.$refs[formName].validate(async valid => {
          if (valid) {
            var data = this.form;
            this.form2.push(data);
            this.deleteForm();
          }
        });
      }
    },
    // 删除工作经历
    async deleteEx(data, index) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示").then(
        async () => {
          if (this.personType) {
            let personDatas;
            if (!this.personData) {
              personDatas = Number(this.firstId);
            } else {
              personDatas = Number(this.personData);
            }
            const deleteForm = {
              highId: data.id,
              governmentId:personDatas
            };
            const res = await deleteGovernmentPrincipleById(deleteForm);

            if (res.code == 200) {
              this.$message.success("删除成功");
              this.search();
            }
          } else {
            this.form2.splice(index, 1);
          }
        }
      );
    },
    // 打开编辑界面
    openEdit(data, index1) {
      this.index = index1;
      this.editform = JSON.parse(JSON.stringify(data));
      Object.assign(this.editform, {
        Authorization: this.Authorization
      });
      // this.$set(this.PayTime, 0, this.editform.beginTime)
      // this.$set(this.PayTime, 1, this.editform.endTime)
      this.PayTime = [
        new Date(this.editform.beginTime),
        new Date(this.editform.endTime)
      ];
      this.dialogFormVisible1 = true;
    },
    // 修改工作经历
    editEx(formName) {
      if (this.personType) {
        this.$refs[formName].validate(async valid => {
          if (valid) {
            const res = await updateGovernment(this.editform);
            if (res.code == 200) {
              this.$message.success("修改成功");
              this.deleteForm();
              this.search();
              this.dialogFormVisible1 = false;
            }
          }
        });
      } else {
        this.$refs[formName].validate(async valid => {
          if (valid) {
            this.form2[this.index] = this.editform;
            this.dialogFormVisible1 = false;
          }
        });
      }
    },
    cancelEx(formName) {
      this.dialogFormVisible1 = false;
      this.$refs[formName].resetFields();
    },
    handleClose() {
      this.deleteForm();
      this.PayTime = [];
    },
    cancelItem() {
      this.$router.go(-1);
    },
    // 跳转上级机构
    async pushNewData(row) {
      // const deleteForm = {
      //   name: row.governmentName
      // };
      // const res = await getGovernmentByName(deleteForm);
      const nameId = row.id.toString();
      let data
      if(this.personData){
        data = this.personData
      }else{
        data = this.firstId
      }
      this.$router.push({
        name: "commercialInfo",
        query: {
          personType: "1",
          secondType: "1",
          // 本条数据id
          firstId: data,
          // 该上级机构的id
          id: nameId,
          name: row.governmentName
        }
      });
      this.reload();
    }
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("researchProject1")[0].style.height =
        document.body.clientHeight - 180 + "px";
    }
  }
};
</script>

<style scoped lang="less">
.el-input__inner {
  width: 200px;
}
.el-button {
  padding: 12px 15px;
}
/deep/.el-dialog {
  width: 700px;
}
.researchProject {
  // height: 560px;
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    padding: 8px 10px;
    margin: 0 20px;
  }
}
/deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  margin-right: 10px;
  width: 210px !important;
}
</style>
