<template>
  <div>
    <el-card>
      <el-tabs v-model="activeName">
        <el-tab-pane label="机构信息" name="first">
          <basic-message
            :editTalentInfo="enterTalentsInfo"
            :personType="personType"
            :personData="personData"
            ref="personMessage"
          ></basic-message>
        </el-tab-pane>
        <el-tab-pane label="机构负责人" name="second">
          <sponsor-info
            :editTalentInfo="enterTalentsInfo"
            :personType="personType"
            :personData="personData"
            ref="sponsorInfo"
          ></sponsor-info>
        </el-tab-pane>
        <el-tab-pane label="上级机构" name="third">
          <organizer-info
            :editTalentInfo="enterTalentsInfo"
            :personType="personType"
            :personData="personData"
            ref="organizerInfo"
          ></organizer-info>
        </el-tab-pane>
        <el-tab-pane label="下级机构" name="fourth">
          <guest-info
            :editTalentInfo="enterTalentsInfo"
            :personType="personType"
            :personData="personData"
            ref="guestInfo"
          ></guest-info>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <div class="bottom" v-if="!personType">
      <el-button type="primary" @click="saveItem">保存</el-button>
      <el-button @click="cancelItem">取消</el-button>
    </div>
  </div>
</template>
<script>
import { saveGovernment,getGovernmentById } from '@/api/commercial.js'
import basicMessage from './editMessage/basicMessage'
import organizerInfo from './editMessage/organizerInfo'
import guestInfo from './editMessage/guestInfo'
import sponsorInfo from './editMessage/sponsorInfo'
import UploadImage from '@/components/UploadImage.vue'
export default {
  name: 'editTalentXS',
  components: {
    basicMessage,
    organizerInfo,
    guestInfo,
    sponsorInfo,
    UploadImage,
  },
  mounted() {
    if(this.personType){
      // document.querySelector('.el-tabs__content').style.height =
      // document.body.clientHeight - 310 + 'px'
    }else{
      document.querySelector('.el-tabs__content').style.height =
      document.body.clientHeight - 240 + 'px'
    }
  },
  computed: {
    personData() {
      return this.$route.query.id
    },
    personType() {
      return this.$route.query.personType
    },
    firstId() {
      return this.$route.query.firstId
    },
    Authorization() {
      return sessionStorage.getItem('token')
    },
  },
  created() {
    if (this.personType) {
      this.ActivityOnes()
    }
  },
  data() {
    return {
      activeName: 'first',
      isShowInput: false,
      // 表单校验信息
      enterTalentsInfo: {
        governmentName:"",//机构名称
        governmentPhone:"",//机构联系电话
        administrativeLevel:"",//行政级别
        country:"",
        province:"",
        city:"",
        address:"",//详细地址
        responsibilities:"",//职责范围
      },
    }
  },
  methods: {
    // 保存全部信息
    saveItem() {
      Object.assign(this.enterTalentsInfo, {
        Authorization: this.Authorization,
      })
      let res
      this.$refs.personMessage.$refs.editTalentInfo.validate(async (valid) => {
        //async
        if (valid) {
          if(this.$refs.sponsorInfo.form2.length>0){
            var allData = {
            governmentDto:this.enterTalentsInfo,
            governmentDtoList:this.$refs.organizerInfo.form2,
            governmentPrincipleDtoList:this.$refs.sponsorInfo.form2
          }
          const res = await saveGovernment(allData)
          if(res.code == 200){
            this.$message.success("新增成功")
            this.$router.go(-1)
          }else{
            this.$message.error(res.msg)
          }
          }else{
            this.$message.error("机构负责人不能为空")
          }

        }
      })
    },
    // 判断是否为字符串
    formatString(val) {
      if (!val) {
        return []
      } else {
        return val.split(',')
      }
    },
    // 返回
    cancelItem() {
      this.$router.go(-1)
    },
    editClick() {
      this.isShowInput = true
    },
    // 查看单个数据
    async ActivityOnes(){
      let personDatas
      if(!this.personData){
      personDatas = Number(this.firstId)
      }else{
      personDatas = Number(this.personData)
      }

      const res = await getGovernmentById({id:personDatas})
      if(res.code == 200){
        this.$message.success("查询成功")
        this.enterTalentsInfo = res.data;
      }else{
        this.$message.error("查询失败")
      }
    }
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-tabs__content {
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}

/deep/ .el-card__body {
  padding: 0px 20px 0px 20px;
}

/deep/ .jr-iconjr-icon-edit1:hover {
          color: #4d77ff;
          cursor: pointer;
        }
</style>
