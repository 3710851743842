<template>
  <div class="researchProjects">
    <div class="index researchProject">
      <div class="topTip">
          <span class="yuanjiao"></span>
          <span style="color: #363e4d; font-weight: 600" class="basicMessage"
            >直属机构</span
          >
        </div>
      <el-table
        class="ly_table"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        :data="form2"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
        <el-table-column
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="governmentName"
          label="机构名称"
        >
        <template slot-scope="scope">
            <div style="cursor: pointer;" @click="pushNewData(scope.row)">
              {{ scope.row.governmentName }}
            </div>
          </template></el-table-column>
        <el-table-column
          prop="governmentPhone"
          label="机构联系电话"
        ></el-table-column>
        <el-table-column
          prop="administrativeLevel"
          label="行政级别"
        ></el-table-column>
        <el-table-column
          prop="principal"
          label="负责人"
        ></el-table-column>
        <el-table-column
          label="任免信息"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.beginTime">
              {{ scope.row.beginTime }}
              {{ scope.row.endTime ? "-" + scope.row.endTime : "-至今" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="responsibilities"
          label="职责范围"
        >
          <template slot-scope="scope">
            <div>
              <el-popover
              placement="right"
              width="300"
              trigger="hover"
              :content="scope.row.responsibilities"
            >
              <div slot="reference">
                <div v-if="(scope.row.responsibilities !== null) && (scope.row.responsibilities !== '')">
                  {{scope.row.responsibilities.substring(0,20)}}
                  <span v-if="scope.row.responsibilities.length>20">...</span>
                </div>
              </div>
            </el-popover>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <button-table
              @buttonClick="openEdit(scope.row, scope.$index)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteEx(scope.row, scope.$index)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <div class="index researchProject" style="margin-top:100px">
      <div class="topTip">
          <span class="yuanjiao"></span>
          <span style="color: #363e4d; font-weight: 600" class="basicMessage"
            >内设机构</span
          >
        </div>
      <el-table
        class="ly_table"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        :data="form3"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
        <el-table-column
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="governmentName"
          label="机构名称"
        >
        <template slot-scope="scope">
            <div style="cursor: pointer;" @click="pushNewData(scope.row)">
              {{ scope.row.governmentName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="governmentPhone"
          label="机构联系电话"
        ></el-table-column>
        <el-table-column
          prop="administrativeLevel"
          label="行政级别"
        ></el-table-column>
        <el-table-column
          prop="principal"
          label="负责人"
        ></el-table-column>
        <el-table-column
          label="任免信息"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.beginTime">
              {{ scope.row.beginTime }}
              {{ scope.row.endTime ? "-" + scope.row.endTime : "-至今" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="responsibilities"
          label="职责范围"
        >
        <template slot-scope="scope">
            <div>
              <el-popover
              placement="right"
              width="300"
              trigger="hover"
              :content="scope.row.responsibilities"
            >
              <div slot="reference">
                <div v-if="(scope.row.responsibilities !== null) && (scope.row.responsibilities !== '')">
                  {{scope.row.responsibilities.substring(0,20)}}
                  <span v-if="scope.row.responsibilities.length>20">...</span>
                </div>
              </div>
            </el-popover>
            </div>
          </template></el-table-column>
        <!-- <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <button-table
              @buttonClick="openEdit(scope.row, scope.$index)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteEx(scope.row, scope.$index)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column> -->
      </el-table>
      <div
        style="color: #7c7f8e; font-size: 13px; padding: 20px"
        v-if="personType"
      >
        <p>创建人：{{editTalentInfo.createName}}</p>
        <p>修改人：{{editTalentInfo.updateName}}</p>
        <p>更新时间：{{editTalentInfo.updateTime}}</p>
        <p>收录时间：{{editTalentInfo.createTime}}</p>
      </div>
    </div>
    <!-- <div class="bottom" v-if="personType">
      <el-button @click="cancelItem" type="primary">确定</el-button>
      <el-button type="info" @click="cancelItem">取消</el-button>
    </div> -->
  </div>
</template>

<script>
import ButtonTable from "@/components/ButtonTable.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import {
  getLowInstitutionsById,
  getGovernmentByName
} from "@/api/commercial.js";
import { pagination } from "@/mixins/pagination";
import CascaderArea from "@/components/CascaderArea.vue";
export default {
  inject: ["reload"],
  mixins: [pagination],
  components: {
    ButtonTable,
    CascaderArea,
    DicCheckboxGroup,
  },
  data() {
    return {
      index: 0,
      form: {
        principal: "", //负责人
        position: "", //职务职称
        administrativeLevel: "", //行政级别
        beginTime: "", //任免信息开始时间
        endTime: "", //任免信息结束时间
        contactInformation: "", //联系方式
        responsibilities: "", //职责范围
      },
      PayTime:[],
      formRules: {
        governmentName: [
          { required: true, message: "请输入负责人", trigger: "blur" },
        ],
        administrativeLevel: [
          { required: true, message: "请输入行政级别", trigger: "blur" },
        ],
      },
      queryInfo: {
        id: "", //人才编号
        pageSize: 10,
        pageNum: 1,
      },
      form2: [],
      form3:[],
      dialogFormVisible: false,
      dialogFormVisible1: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      editform: {
        principal: "", //负责人
        position: "", //职务职称
        administrativeLevel: "", //行政级别
        beginTime: "", //任免信息开始时间
        endTime: "", //任免信息结束时间
        contactInformation: "", //联系方式
        responsibilities: "", //职责范围
      },
    };
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
    firstId() {
      return this.$route.query.firstId
    },
  },
  props: {
    editTalentInfo: {
      type: Object,
    },
    personData: {
      type: String,
    },
    personType: {
      type: String,
    },
  },
  created() {
    if (this.personType) {
      this.search();
    }
  },
  methods: {
    // 查询
    async search() {
      let personDatas
      if(!this.personData){
      personDatas = Number(this.firstId)
      }else{
      personDatas = Number(this.personData)
      }
      if (this.personType) {
        this.queryInfo.id = personDatas;
      }
      const res = await getLowInstitutionsById({id:personDatas});
      if (res.code === 200) {
        this.form2 = res.data.zgovernmentResults
        this.form3 = res.data.ngovernmentResults
      }
    },
    async pushNewData(row) {
      // const deleteForm = {
      //   name: row.governmentName
      // };
      // const res = await getGovernmentByName(deleteForm);
      const nameId = row.id.toString();
      let data 
      if(this.personData){
        data = this.personData
      }else{
        data = this.firstId
      }
      this.$router.push({
        name: "commercialInfo",
        query: {
          personType: "1",
          secondType: "1",
          firstId: data,
          id: nameId,
          name: row.governmentName
        }
      });
      this.reload();
    }
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("researchProjects")[0].style.height =
        document.body.clientHeight - 180 + "px";
    }
  },
};
</script>

<style scoped lang="less">
.el-input__inner {
  width: 200px;
}
.el-button {
  padding: 12px 15px;
}
/deep/.el-dialog {
  width: 645px;
}
.researchProjects {
  // height: 560px;
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    padding: 8px 10px;
    margin: 0 20px;
  }
}
.topTip {
    margin-bottom: 5px;
    .yuanjiao {
      border: 2px solid #1e9fff;
      height: 20px;
      background-color: #1e9fff;
      border-radius: 5px;
    }
    .basicMessage {
      display: inline-block;
      margin-left: 10px;
      margin-bottom: 9px;
    }
  }
</style>
