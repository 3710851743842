<template>
  <div>
    <div class="personMessage">
      <el-form
        :model="editTalentInfo"
        ref="editTalentInfo"
        :rules="ruleFormrules"
        label-width="100px"
        v-if="isShow"
      >
        <el-form-item
          label="机构名称:"
          prop="governmentName"
          label-width="100px"
        >
          <el-input
            v-model="editTalentInfo.governmentName"
            style="width: 470px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="行政级别:"
          label-width="100px"
          prop="administrativeLevel"
        >
          <!-- <el-checkbox-group v-model="editTalentInfo.administrativeLevel">
            <dic-radio-group
              :code.sync="editTalentInfo.administrativeLevel"
              type-code="031"
            />
          </el-checkbox-group> -->
          <el-select v-model="editTalentInfo.administrativeLevel" clearable placeholder="请选择">
            <el-option label="国级" value="国级" />
            <el-option label="省（部）级" value="省（部）级" />
            <el-option label="厅（局）级" value="厅（局）级" />
            <el-option label="处级" value="处级" />
            <el-option label="科级" value="科级" />
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话:" label-width="100px">
          <el-input
            style="width: 465px"
            v-model="editTalentInfo.governmentPhone"
          ></el-input>
        </el-form-item>
        <el-form-item label="所在地区:" label-width="100px" prop="country">
          <cascader-area
            :country="editTalentInfo.country"
            :province="editTalentInfo.province"
            :city="editTalentInfo.city"
            @searchCountry="searchCountry"
            @searchProvince="searchProvince"
            @searchCity="searchCity"
          />
        </el-form-item>
        <el-form-item label="详细地址:" label-width="100px">
          <el-input
            v-model="editTalentInfo.address"
            style="width: 465px"
          ></el-input>
        </el-form-item>
        <el-form-item label="职责范围:" label-width="100px">
          <el-input
            v-model="editTalentInfo.responsibilities"
            style="width: 80%"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 20 }"
          ></el-input>
        </el-form-item>
        <el-form-item label="关键词:" label-width="100px">
          <el-input
            v-model="editTalentInfo.keyWord"
            style="width: 80%"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 20 }"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        style="color: #7c7f8e; font-size: 13px; padding: 20px"
        v-if="personType"
      >
        <p>创建人：{{ editTalentInfo.createName }}</p>
        <p>修改人：{{ editTalentInfo.updateName }}</p>
        <p>更新时间：{{ editTalentInfo.updateTime }}</p>
        <p>收录时间：{{ editTalentInfo.createTime }}</p>
      </div>
    </div>
    <div v-if="personType && !secondType" class="bottom">
      <el-button @click="saveTalents()" type="primary">确定</el-button>
      <el-button type="info" @click="cancelItem">取消</el-button>
    </div>
    <div class="bottom" v-if="personType && secondType">
      <el-button type="info" @click="cancelItem">返回</el-button>
    </div>
  </div>
</template>

<script>
import { updateGovernment } from "@/api/commercial";
import DicCheckboxGroupTalent from "@/components/DicCheckboxGroupTalent.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import DicRadioGroup from "@/components/DicRadioGroup.vue";
import CascaderArea from "@/components/CascaderArea.vue";
export default {
  inject: ["reload"],
  components: {
    CascaderArea,
    DicRadioGroup,
    DicCheckboxGroupTalent,
    DicCheckboxGroup,
  },
  watch: {
    "editTalentInfo.id": {
      handler(newVal) {
        if (this.editTalentInfo && newVal) {
          this.isShow = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    if (!this.personType) {
      this.isShow = true;
    }
  },
  data() {
    return {
      restaurants: [],
      isShow: false,
      imageUrl: "",
      caseDataInfo: {
        imgLook: "",
        simgpathImg: "",
        list: [],
      },
      ruleFormrules: {
        governmentName: [
          { required: true, message: "请填写机构名称", trigger: "change" },
        ],
        administrativeLevel: [
          { required: true, message: "请选择行政级别", trigger: "change" },
        ],
        country: [
          { required: true, message: "请选择所在地区", trigger: "change" },
        ],
      },
    };
  },
  props: {
    editTalentInfo: {
      type: Object,
    },
    personType: {
      type: String,
    },
    personData: {
      type: String,
    },
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
    demandType() {
      return this.$route.query.demandType;
    },
    firstId() {
      return this.$route.query.firstId;
    },
    secondType() {
      return this.$route.query.secondType;
    },
  },
  methods: {
    // 保存企业人才信息
    async saveTalents() {
      Object.assign(this.editTalentInfo, {
        Authorization: this.Authorization,
      });
      const res = await updateGovernment(this.editTalentInfo);
      if (res.code === 200) {
        if (this.demandType) {
          this.$router.push({
            name: "Commercial",
          });
        } else {
          this.$message.success("编辑成功");
          this.$router.push({
            name: "Commercial",
          });
        }
      } else {
        this.$message.error("编辑失败");
      }
    },
    cancelItem() {
      // if (this.demandType) {
      //   this.$router.go(-2);
      // } else {
      if (this.secondType) {
        this.$router.push({
          name: "commercialInfo",
          query: {
            personType: "1",
            // secondType: "",
            firstId: this.firstId,
            // id: this.personData,
            // id:''
          },
        });
        this.reload();
      } else {
        this.$router.push({
          name: "Commercial",
        });
      }
      // }
    },
    searchCountry(val) {
      this.editTalentInfo.country = val;
    },
    searchProvince(val) {
      this.editTalentInfo.province = val;
    },
    searchCity(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.editTalentInfo.city = val;
      }
    },
    lookClick(val) {
      this.editTalentInfo.activityImageLook = val;
    },
    saveClick(val) {
      this.editTalentInfo.activityImage = val;
    },
    lookClickPoster(val) {
      this.editTalentInfo.activityPostersLook = val;
    },
    saveClickPoster(val) {
      this.editTalentInfo.activityPosters = val;
    },
    updateCode(val, tag) {
      switch (tag) {
        case "领域":
          this.editTalentInfo.fieldChildrenId = val;
          break;

        case "技术":
          this.editTalentInfo.techChildrenId = val;
          break;

        case "行业":
          this.editTalentInfo.industryChildrenId = val;
          break;

        default:
          break;
      }
    },

    // 单位名称弹出框
    // async getCompanyList(){
    //   const res = await samrtFind();
    //   this.restaurants = res.data
    // }
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("personMessage")[0].style.height =
        document.body.clientHeight - 235 + "px";
    }
  },
};
</script>

<style lang="less" scoped>
// /deep/ .el-input {
//   width: 450px;
// }
.personMessage {
  // 蓝色圆角化
  margin-bottom: 6px;
  // height: 560px;
  overflow: auto;
  .el-select {
    input::-webkit-input-placeholder {
      color: #1f212b;
    }
    input::-moz-input-placeholder {
      color: #1f212b;
    }
    input::-ms-input-placeholder {
      color: #1f212b;
    }
    margin-right: 8px;
  }
  .el-button {
    padding: 12px 38px;
  }
  /deep/ .el-textarea__inner {
    height: 100%;
  }
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
// 规定图片大小
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 148px;
  height: 148px;
}
</style>
